import { render, staticRenderFns } from "./AffiliateTermsAndConditions.vue?vue&type=template&id=12abccee"
import script from "./AffiliateTermsAndConditions.ts?vue&type=script&lang=ts&external"
export * from "./AffiliateTermsAndConditions.ts?vue&type=script&lang=ts&external"
import style0 from "./AffiliateTermsAndConditions.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports